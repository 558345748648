import { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [urlInput, setUrlInput] = useState("");
  const [shortenedUrl, setShortenedUrl] = useState("Please set URL");
  const [copied, setCopied] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [countData, setCountData] = useState("");

  const apiKey = process.env.REACT_APP_API_KEY;
  const DomainKey = process.env.REACT_APP_DOMAIN;

  const handleUrlChange = (event) => {
    setUrlInput(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.hypershortener.com/~count");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setCountData(jsonData);
      } catch (error) {
        console.log("Something went wrong");
      }
    };

    fetchData();
  }, []);

  const shortenUrl = async () => {
    try {
      let isValidUrl;
      try {
        new URL(urlInput);
        isValidUrl = true;
      } catch (error) {
        isValidUrl = false;
      }

      if (!isValidUrl) {
        setShortenedUrl("Please enter a valid URL");
        setShowResult(true);
        return;
      }

      const response = await fetch(apiKey, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: urlInput, domain: DomainKey }),
      });

      if (!response.ok) {
        setShortenedUrl("Please enter a valid URL");
      } else {
        const data = await response.json();
        setShortenedUrl(data.url);
        setShowResult(true);
      }
    } catch (error) {
      console.error("Error shortening URL:", error);
      setShortenedUrl("Please enter a valid URL");
      setShowResult(true);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shortenedUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 4000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <>
      <div className="MainSection">
        <div className="AmountSection">
          <div className="Block">
            <p className="Title">Last 30 Days</p>
            <p className="Number">{738 + countData.links30Days}</p>
          </div>
          <div className="Block">
            <p className="Title">Total</p>
            <p className="Number">{2111 + countData.linksTotal}</p>
          </div>
          <div className="Block">
            <p className="Title">Last Year</p>
            <p className="Number">{2111 + countData.links1Year}</p>
          </div>
        </div>
        <div className="LOGO"></div>

        <div className="SloganSection">
          <p>Shorten your links swiftly! 🚀</p>
        </div>

        <div className="InputSection">
          <input
            className="input"
            type="text"
            value={urlInput}
            onChange={handleUrlChange}
            placeholder="Enter full URL"
          />

          <button className="GenerateButton" onClick={shortenUrl}>
            Shortify
          </button>
        </div>

        {showResult && (
          <div className={`ResultSection flip-in`}>
            <h4>Your Shortlink</h4>

            <div className="ShortenedUrlContainer">
              <h1
                onClick={handleCopyClick}
                className={`ShortenedURL ${
                  shortenedUrl === "Please enter a valid URL" ? "" : "bounce-in"
                }`}
              >
                {shortenedUrl}
              </h1>
              <FontAwesomeIcon
                icon={faCopy}
                className="CopyIcon"
                onClick={handleCopyClick}
              />
            </div>

            {copied && (
              <span className="tooltip">
                <h3>Copied to Clipboard</h3>
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default App;
